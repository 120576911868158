<template>
  <div>
    <div v-if="!expanded" class="non-expanded-header" @click="toggleExpand">
      <div v-if="data.name" class="bom-item-name">{{ data.name }}</div>
      <div v-else class="bom-item-no-name">Untitled</div>
      <div>
        <v-icon class="expand-icon">mdi-plus </v-icon>
      </div>
    </div>
    <div v-else class="expanded-header">
      <v-text-field
        class="title-input"
        v-model="data.name"
        @input="debounceBomData()"
        background-color="white"
        maxlength="1000"
        outlined
        dense
      ></v-text-field>
      <div @click="toggleExpand">
        <v-icon class="shrink-icon">mdi-minus </v-icon>
      </div>
    </div>
    <div v-if="expanded">
      <div class="expanded-footer">
        <v-tooltip color="#473068" bottom>
          <template v-slot:activator="{ on }">
            <div v-on="on">
              <div @click="removeRow(data.id)">
                <v-icon class="delete-icon">mdi-delete</v-icon>
              </div>
            </div>
          </template>
          <div class="hover-container">
            <span>Remove BOM Item</span>
          </div>
        </v-tooltip>
      </div>
      <div class="expanded-component">
        <div class="supplier-dropdown-container">
          <v-autocomplete
            v-model="data.supplier_ids"
            :items="suppliers"
            item-text="name"
            item-value="id"
            multiple
            @input="debounceBomData()"
            :disabled="!isAccountVerified"
            :menu-props="{
              closeOnContentClick: !suppliers || !suppliers.length,
            }"
            label="Select Suppliers"
            class="dropdown-list"
          >
            <template v-slot:selection="{}"> </template>
            <template v-slot:no-data>
              <div class="no-suppliers-text" @click="changeTab">
                No suppliers. Click here to assign.
              </div>
            </template>
          </v-autocomplete>
          <div v-if="!isAccountVerified" class="not-verified-text">
            Your company profile needs to be verified to select a supplier or
            factory.
          </div>
        </div>
        <div class="expanded-body">
          <div class="expanded-body-item">
            <div>
              <table class="supplier-table">
                <tr
                  v-for="(supplierId, index) in data.supplier_ids"
                  :key="index"
                >
                  <td class="table-data">
                    <div class="supplier-name">
                      {{
                        suppliers.find((supplier) => supplier.id == supplierId)
                          .name
                      }}
                    </div>
                  </td>
                  <td class="table-data">
                    <div>
                      <v-autocomplete
                        :ref="`factory-select-${supplierId}`"
                        :items="[
                          ...(suppliers.find(
                            (supplier) => supplier.id == supplierId
                          )?.account
                            ? [
                                {
                                  id: useCompanyAsFactoryId,
                                  name: 'Use the company as the factory',
                                },
                              ]
                            : []),
                          ...suppliers
                            .filter((supplier) => supplier.id == supplierId)
                            .find((supplier) => supplier.id == supplierId)
                            .factories,
                        ]"
                        item-text="name"
                        item-value="id"
                        v-model="data.factories[supplierId]"
                        :disabled="!isAccountVerified"
                        multiple
                        label="Select factories"
                        @input="debounceBomData({ refresh: true, force: true })"
                        class="dropdown-list"
                      >
                        <template v-slot:prepend-item>
                          <div
                            @click="
                              unableToFindFactory(
                                suppliers
                                  .filter(
                                    (supplier) => supplier.id == supplierId
                                  )
                                  .find((supplier) => supplier.id == supplierId)
                              )
                            "
                            class="unable-to-find-factory"
                          >
                            I'm unable to find the factory
                          </div>
                        </template>
                        <template v-slot:selection="{}"></template>
                        <template v-slot:no-data>
                          <v-list-item>
                            <v-list-item-content>
                              <v-list-item-title>
                                No factories available for the selected
                                supplier.
                              </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                      </v-autocomplete>
                      <div
                        v-if="
                          data.factories[supplierId]?.includes(
                            useCompanyAsFactoryId
                          )
                        "
                        class="factory-name"
                      >
                        The company is used as the factory
                        <span
                          class="no-location-warning"
                          v-if="
                            !suppliers.find(
                              (supplier) => supplier.id == supplierId
                            )?.account?.city ||
                            !suppliers.find(
                              (supplier) => supplier.id == supplierId
                            )?.account?.country
                          "
                        >
                          <br />Selected company does not have a location to
                          display yet
                        </span>
                      </div>
                      <div
                        v-for="(factory, index) in suppliers
                          .filter((supplier) => supplier.id == supplierId)
                          .find((supplier) => supplier.id == supplierId)
                          .factories.filter((factory) =>
                            data.factories[supplierId]?.find(
                              (factoryId) => factoryId == factory.id
                            )
                          )"
                        :key="index"
                      >
                        <div class="factory-card">
                          <div class="factory-name">{{ factory.name }}</div>
                          <div v-if="isAuthority">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  v-if="
                                    data.suppliers.find(
                                      (supplier) =>
                                        supplier.factory_id === factory.id
                                    )?.verified_by_brand
                                  "
                                  color="#473068"
                                  class="mr-2"
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  mdi-check
                                </v-icon>
                                <v-icon
                                  v-else
                                  color="#B8B8B8"
                                  class="mr-2"
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  mdi-check-disabled
                                </v-icon>
                              </template>
                              <span>Verified by Brand</span>
                            </v-tooltip>
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  v-if="
                                    data.suppliers.find(
                                      (supplier) =>
                                        supplier.factory_id === factory.id
                                    )?.verified_by_factory
                                  "
                                  color="#473068"
                                  class="mr-2"
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  mdi-check
                                </v-icon>
                                <v-icon
                                  v-else
                                  color="#B8B8B8"
                                  class="mr-2"
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  mdi-check-disabled
                                </v-icon>
                              </template>
                              <span>Verified by Factory</span>
                            </v-tooltip>
                          </div>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </table>
            </div>
            <div class="textarea-container">
              <div class="input-label">Composition</div>
              <div
                v-for="(item, index) in data.compositions"
                :key="index"
                class="item-row"
              >
                <v-combobox
                  :items="compositions.map((composition) => composition.name)"
                  v-model="item.composition"
                  label="Material"
                  class="item-select"
                  maxlength="255"
                  dense
                  outlined
                  background-color="white"
                  @input="debounceBomData()"
                >
                </v-combobox>
                <v-text-field
                  v-model="item.percentage"
                  label="Percentage"
                  prefix="%"
                  class="item-input"
                  type="number"
                  min="0"
                  max="100"
                  outlined
                  dense
                  background-color="white"
                  reverse
                  @input="debounceBomData()"
                ></v-text-field>
                <v-icon
                  @click="removeComposition(item.id)"
                  class="delete-composition-icon"
                  >mdi-delete</v-icon
                >
              </div>
              <div
                v-if="
                  data.compositions.length &&
                  sumCompositionPercentages !== 100 &&
                  duplicateCompositionsDetected
                "
                class="error-message"
              >
                <div class="error-content">
                  <span class="composition-text"
                    >Duplicate materials detected.</span
                  >
                  <span class="percentage-text">Total should be 100%</span>
                </div>
              </div>
              <div
                v-else-if="
                  data.compositions.length && sumCompositionPercentages !== 100
                "
                class="error-message"
              >
                Total should be 100%
              </div>
              <div
                v-else-if="
                  data.compositions.length && duplicateCompositionsDetected
                "
                class="error-message composition-text"
              >
                Duplicate materials detected.
              </div>
              <div>
                <v-icon
                  v-if="!insertingNewComposition"
                  @click="insertNewComposition"
                  class="new-composition-icon"
                  >mdi-plus
                </v-icon>
                <v-progress-circular
                  v-else
                  indeterminate
                  :color="$parent.$el.style.color"
                  size="20"
                  width="1"
                  style="float: left"
                ></v-progress-circular>
              </div>
            </div>
          </div>
          <div class="expanded-body-item">
            <div>
              <table class="measurement-table">
                <thead>
                  <tr>
                    <th class="table-header">Measuring Unit</th>
                    <th class="table-header">Consumption per Garment</th>
                    <th class="table-header">Total</th>
                    <th class="table-header">Total Consumption</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="table-data">
                      <div class="td-container">
                        <v-autocomplete
                          class="selection_list"
                          :items="measurements"
                          item-text="measurement_name"
                          item-value="id"
                          v-model="data.measuring_unit"
                          label=""
                          @input="debounceBomData()"
                        >
                        </v-autocomplete>
                      </div>
                    </td>
                    <td class="table-data">
                      <div class="td-container">
                        <v-text-field
                          v-model="data.consumption_per_garment"
                          type="number"
                          min="0"
                          step="0.01"
                          @keypress="
                            isValidDecimal($event, data.consumption_per_garment)
                          "
                          @input="debounceBomData()"
                        ></v-text-field>
                      </div>
                    </td>
                    <td class="table-data">
                      <div class="td-container">
                        <v-text-field
                          v-model="data.total_quantity"
                          type="number"
                          min="0"
                          step="1"
                          @keypress="isNumberKey($event)"
                          @input="debounceBomData()"
                        ></v-text-field>
                      </div>
                    </td>
                    <td class="table-data">
                      <div class="td-container calculated-total">
                        <div
                          v-if="
                            data.consumption_per_garment && data.total_quantity
                          "
                          style="text-align: left; margin-top: 6px"
                        >
                          {{
                            (
                              data.consumption_per_garment * data.total_quantity
                            ).toFixed(2)
                          }}
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="textarea-container">
                <div class="input-label">Description</div>
                <v-textarea
                  class="description-textarea"
                  auto-grow
                  rows="5"
                  row-height="15"
                  v-model="data.comments"
                  background-color="white"
                  maxlength="65535"
                  @input="debounceBomData()"
                  outlined
                ></v-textarea>
              </div>
            </div>
          </div>
        </div>
        <div class="files-container-component">
          <div class="input-label">Files</div>
          <div :id="`files-container-${data.id}`" class="files-container">
            <div class="new-file-button" @click="$refs.file.click()">
              <input
                :id="`chooseFiles` + data.id"
                type="file"
                style="display: none"
                ref="file"
                multiple="multiple"
                :accept="acceptedFileTypes"
                @change="onFileChange($event, data.id)"
              />
              <v-icon
                v-if="filesUploadingProgress === null"
                class="new-file-icon"
                >mdi-plus</v-icon
              >
              <v-progress-circular
                v-else
                :rotate="270"
                :size="50"
                :width="5"
                :value="filesUploadingProgress"
                color="teal"
              >
                {{ filesUploadingProgress }}
              </v-progress-circular>
            </div>
            <div class="files-container-overflow">
              <v-slide-group
                v-if="data.files && data.files.length"
                multiple
                class="files-slider"
                show-arrows="always"
              >
                <v-slide-item
                  v-for="file in data.files"
                  v-bind:key="'slider-item-' + file.id"
                >
                  <div>
                    <div
                      :style="`border: 0.5px solid #442d64; ${
                        isImage(file) ? 'cursor: pointer' : ''
                      }`"
                      @click="handlePreview(isImage(file) ? file : null)"
                    >
                      <div
                        class="image-size"
                        :style="
                          isImage(file)
                            ? `background-image: url(${baseUrl}/api/inquiries/file_show/${file.upload_name});`
                            : `background-image: url(${imageFile});`
                        "
                      >
                        <div
                          :id="`more-vert-${file.id}`"
                          @click.stop="options(file.id)"
                          class="more-vert"
                        >
                          <v-icon class="more-vert-icon">more_vert</v-icon>
                        </div>
                        <div class="file-size">
                          {{
                            file.file_size > 1000
                              ? (file.file_size / 1024).toFixed(2) + " " + "Mb"
                              : file.file_size + " " + "Kb"
                          }}
                        </div>
                      </div>
                      <div class="file-name">
                        <truncate-hovering
                          :text="fileTitle(file.title)"
                          :length="5"
                          :appendText="'.' + fileExtension(file.title)"
                          :displayAppendOnHover="true"
                        />
                      </div>
                    </div>
                  </div>
                </v-slide-item>
                <template v-slot:prev>
                  <v-btn
                    size="x-small"
                    icon
                    outlined
                    color="#473068"
                    style="border-radius: 8px"
                  >
                    <v-icon>mdi-chevron-left</v-icon>
                  </v-btn>
                </template>
                <template v-slot:next>
                  <v-btn
                    size="x-small"
                    color="#473068"
                    icon
                    outlined
                    style="border-radius: 8px"
                  >
                    <v-icon>mdi-chevron-right</v-icon>
                  </v-btn>
                </template>
              </v-slide-group>
              <div
                v-for="file in data.files"
                :key="'actions-container-' + file.id"
              >
                <div
                  :id="`files-action-container-${file.id}`"
                  class="actions-container"
                >
                  <div class="actions-list" @click="downloadFile(file)">
                    Download
                  </div>
                  <div class="actions-list" @click="fileRename(file)">
                    Rename
                  </div>
                  <div
                    class="actions-list"
                    @click="setMainImage(file.upload_name, data.name)"
                    v-if="isAuthority && isImage(file)"
                  >
                    Set as main image
                  </div>
                  <div class="actions-list" @click="deleteFile(file)">
                    Delete
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <popup-window
      :active="showPreview"
      :fullScreen="true"
      maxWidth="fit-content"
      width="fit-content"
      @popup-close="handlePreview()"
      @popup-back="handlePreview()"
    >
      <div>
        <img :src="previewingFile" style="max-width: 500px" />
      </div>
    </popup-window>
    <popup-window
      :active="showRenameForm"
      :hasClose="false"
      noMargin
      @popup-close="resetForms"
      @popup-back="resetForms"
    >
      <div class="rename-form">
        <div class="rename-title">Rename file name to:</div>
        <input
          v-model="newFileName"
          class="rename-input"
          type="text"
          placeholder="Rename file name"
          id="rename"
          name="rename"
        />
        <div class="rename-room-buttons">
          <ui-button
            class="primary short space"
            @click="setNewFileName"
            title="✔ Ok"
          />
          <ui-button class="lighter short" @click="resetForms" title="Cancel" />
        </div>
      </div>
    </popup-window>
    <popup-window
      :active="unableToFindFactoryPopup.active"
      :hasClose="false"
      noMargin
      @popup-close="unableToFindFactoryPopup.active = false"
      @popup-back="unableToFindFactoryPopup.active = false"
    >
      <factory-form
        ref="factoryCreationFormBom"
        :loading="unableToFindFactoryPopup.creating"
        :isRequest="
          unableToFindFactoryPopup.isSupplierRegistered &&
          !unableToFindFactoryPopup.isMyCompanyTheSupplier
        "
        @close="closeFactoryForm"
        @create="
          (formData) =>
            unableToFindFactoryPopup.isSupplierRegistered &&
            !unableToFindFactoryPopup.isMyCompanyTheSupplier
              ? requestFactory(formData)
              : createFactory(formData)
        "
      />
    </popup-window>
  </div>
</template>

<script>
/* eslint-disable @typescript-eslint/camelcase */
import FactoryForm from "../FactoryForm.vue";
import restAdapter from "../../restAdapter";
import { isImageFile } from "../../utils/media-type";
import * as conf from "../../conf.yml";
import utils from "../../utils";
import debounce from "lodash/debounce";
import notification from "../../notification";
import { validFilesSize } from "../../utils/file-size";
import { isNumberKey, isDecimalKey } from "../../utils/input-key";
import auth from "@/auth";
import store from "@/store";

export default {
  name: "BomItem",
  components: {
    FactoryForm,
  },
  data: () => ({
    imageFile: require(`../../assets/folder.png`),
    useCompanyAsFactoryId: "useCompanyAsFactory",
    actionId: null,
    acceptedFileTypes: utils.acceptedFileTypes,
    showRenameForm: false,
    newFileName: null,
    renameFile: null,
    showPreview: false,
    previewingFile: "",
    bomItemId: null,
    expanded: false,
    activeActionsId: "",
    isNew: false,
    selectedSupplierId: null,
    selectedFactory: null,
    showFactorySelect: false,
    filesUploadingProgress: null,
    insertingNewComposition: false,
    unableToFindFactoryPopup: {
      active: false,
      supplier: null,
      creating: false,
      isSupplierRegistered: false,
      isMyCompanyTheSupplier: false,
    },
  }),

  props: {
    isAuthority: {
      type: Boolean,
      default: false,
    },
    headerHeight: {
      type: Number,
      suppliers: Array,
      factories: Array,
      default: 0,
    },
    inquiryId: {
      type: [String, Number],
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
    baseUrl: {
      type: String,
      default: conf.default.server.host,
    },
    category: {
      type: Object,
      required: true,
    },
    measurements: {
      type: Array,
      default: () => [],
    },
    suppliers: {
      type: [Array, Object],
      default: () => [],
    },
    compositions: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    sumCompositionPercentages() {
      return this.data.compositions.reduce(
        (acc, composition) => acc + Number(composition.percentage || 0),
        0
      );
    },
    duplicateCompositionsDetected() {
      const compositionNames = this.data.compositions.map(
        (composition) => composition.composition
      );

      return new Set(compositionNames).size !== compositionNames.length;
    },
    isAccountVerified() {
      return auth.user.account?.verified;
    },
  },
  methods: {
    toggleExpand() {
      this.expanded = !this.expanded;
    },
    changeTab() {
      this.$emit("change-tab", "suppliers");
    },
    isNumberKey(evt) {
      if (isNumberKey(evt)) return true;
      return evt.preventDefault();
    },
    isValidDecimal(evt, value) {
      if (isDecimalKey(evt, value)) return true;
      return evt.preventDefault();
    },
    isImage(file) {
      return isImageFile(file);
    },
    unableToFindFactory(supplier) {
      this.unableToFindFactoryPopup.isSupplierRegistered = false;
      this.unableToFindFactoryPopup.isMyCompanyTheSupplier = false;
      if (supplier.account) {
        this.unableToFindFactoryPopup.isSupplierRegistered = true;
        if (supplier.id === auth.user.account.organization_registry_id) {
          this.unableToFindFactoryPopup.isMyCompanyTheSupplier = true;
        }
      }
      this.$refs[`factory-select-${supplier.id}`][0].blur();
      this.unableToFindFactoryPopup.supplier = supplier;
      this.unableToFindFactoryPopup.active = true;
    },
    closeFactoryForm() {
      this.unableToFindFactoryPopup.active = false;
    },
    createFactory(factory) {
      this.unableToFindFactoryPopup.creating = true;
      restAdapter
        .post(`/api/factories`, {
          name: factory.name,
          established_city: factory.establishedCity,
          established_country: factory.establishedCountry,
          global_organization_id: this.unableToFindFactoryPopup.supplier.id,
        })
        .then((response) => {
          const suppliers = this.suppliers.map((supplier) => {
            if (supplier.id === this.unableToFindFactoryPopup.supplier.id) {
              supplier.factories = [
                ...supplier.factories,
                { ...response.data.factory },
              ];
            }
            return supplier;
          });
          this.$emit("update-suppliers", suppliers);
          this.unableToFindFactoryPopup.creating = false;
          this.$refs.factoryCreationFormBom.close();
          this.closeFactoryForm();
          store.commit(
            "SET_UPDATE_INQUIRY_SUPPLIER",
            !this.$store.state.inquirySuppliersUpdated
          );
          notification.success("Factory added successfully");
        })
        .catch((error) => {
          this.unableToFindFactoryPopup.creating = false;
          this.$refs.factoryCreationFormBom.close();
          this.closeFactoryForm();
          notification.error("Something went wrong.");
        });
    },
    requestFactory(factory) {
      this.unableToFindFactoryPopup.creating = true;
      restAdapter
        .post(`/api/factory_requests`, {
          name: factory.name,
          city: factory.establishedCity,
          country: factory.establishedCountry,
          organization_id: this.unableToFindFactoryPopup.supplier.id,
        })
        .then((response) => {
          this.unableToFindFactoryPopup.creating = false;
          this.$refs.factoryCreationFormBom.close();
          this.closeFactoryForm();
          notification.success("New factory requested successfully");
        })
        .catch((error) => {
          this.unableToFindFactoryPopup.creating = false;
          this.$refs.factoryCreationFormBom.close();
          this.closeFactoryForm();
          notification.error("Something went wrong.");
        });
    },
    removeRow(id) {
      this.$emit("remove-row", id);
    },
    insertNewComposition() {
      this.insertingNewComposition = true;
      this.$emit("add-data", {
        refresh: true,
        data: {
          ...this.data,
          compositions: [
            ...this.data.compositions,
            { composition: "", percentage: "" },
          ],
        },
        callback: () => {
          this.insertingNewComposition = false;
        },
      });
    },
    removeComposition(id) {
      if (!confirm("Are you sure you want to delete this composition?")) return;
      this.$emit("add-data", {
        refresh: true,
        data: {
          ...this.data,
          compositions: this.data.compositions.filter(
            (composition) => composition.id !== id
          ),
        },
      });
    },
    options(id) {
      this.resetActionContainers();
      this.actionId = this.actionId == id ? null : id;
      if (this.actionId) {
        const container = document.getElementById(
          `files-action-container-${id}`
        );

        const leftPosition = document
          .getElementById(`more-vert-${id}`)
          .getBoundingClientRect().left;

        const leftPositionParent = document
          .getElementById(`files-container-${this.data.id}`)
          .getBoundingClientRect().left;

        container.style.left = `${leftPosition - leftPositionParent - 105}px`;
        container.style.display = "inherit";
      }
    },

    handlePreview(file) {
      if (!file) {
        this.showPreview = false;
        this.previewingFile = "";
      } else {
        this.showPreview = true;
        this.previewingFile = `${conf.server.host}/api/inquiries/file_show/${file.upload_name}`;
      }
    },

    downloadFile(file) {
      this.resetActionContainers();
      window.open(
        `${conf.server.host}/api/inquiries/file_download/${file.upload_name}`
      );
    },

    async deleteFile(file) {
      this.resetActionContainers();
      if (confirm("Delete this file?")) {
        this.$emit("add-data", {
          callback: () =>
            restAdapter
              .delete(
                `/api/inquiries/${this.inquiryId}/bom_file_delete/${file.upload_name}`
              )
              .then(() => {
                notification.success("File deleted successfully");
                this.$emit("refresh");
              }),
        });
      }
    },

    resetForms() {
      this.showRenameForm = false;
      this.newFileName = null;
      this.renameFile = null;
      this.actionId = null;
      this.resetActionContainers();
    },

    resetActionContainers() {
      document
        .querySelectorAll("[id^=files-action-container]")
        .forEach((el) => {
          el.style.display = "none";
        });
    },

    fileTitle(title) {
      return title.split(".")[0];
    },

    fileExtension(title) {
      return title.split(".").pop();
    },

    fileRename(file) {
      this.resetActionContainers();
      this.renameFile = file;
      this.showRenameForm = true;
    },

    async setNewFileName() {
      this.$emit("add-data", {
        callback: () =>
          restAdapter
            .put(
              `/api/inquiries/${this.inquiryId}/bom_file_rename/${this.renameFile.upload_name}`,
              { new_name: this.newFileName }
            )
            .then(() => {
              notification.success("File renamed successfully");
              this.resetForms();
              this.$emit("refresh");
            }),
      });
    },

    refreshLog() {
      this.$emit("refresh-log");
    },

    requestVerification(item) {
      restAdapter
        .post(
          `/api/assignments/${item.id}/change_verification_requested_status`,
          {
            is_requested: !item.verification_requested,
            type: "bom",
          }
        )
        .then((res) => {
          this.$emit("add-data", {
            refresh: true,
            callback: () => {
              if (item.verification_requested)
                notification.success(
                  `Verification request removed successfully`
                );
              else notification.success(`Verification requested successfully`);
            },
          });
        });
    },

    debounceBomData(debounceOptions = {}) {
      const debounced = debounce(async () => {
        this.$emit("add-data", { refresh: debounceOptions.refresh });
      }, 5000);
      if (debounceOptions.force) this.$emit("add-data", { refresh: true });
      else debounced();
    },

    itemApproval(id) {
      restAdapter
        .put(`/api/inquiries/${this.inquiryId}/approve_bom_item/${id}`)
        .then((response) => {
          this.refreshLog();
          notification.success("Status Updated Successfully");
        });
    },

    async onFileChange(event, bomId) {
      const files = event.target.files;
      if (!validFilesSize(files)) {
        notification.error("Files size limit exceeded");
        return;
      }
      this.$emit("add-data", {
        callback: () => {
          let progress = 0;
          if (files.length !== 0) {
            const formData = new FormData();
            for (let i = 0; i < files.length; i++) {
              const file = files[i];
              formData.append("file[" + i + "]", file);
            }
            event.target.value = "";
            restAdapter
              .post(
                `/api/inquiries/${this.inquiryId}/bom_file_upload/${bomId}`,
                formData,
                {
                  onUploadProgress: (progressEvent) => {
                    progress = Math.round(
                      (progressEvent.loaded * 100) / progressEvent.total
                    );
                    this.filesUploadingProgress = progress;
                  },
                }
              )
              .then(() => {
                this.filesUploadingProgress = null;
                notification.success("File Uploaded Successfully");
                this.$emit("refresh");
              })
              .catch((error) => {
                this.filesUploadingProgress = null;
                if (error.response?.data?.message) {
                  notification.error(error.response.data.message);
                } else {
                  notification.error("Something went wrong.");
                }
              });
          }
        },
      });
    },

    async fileDelete() {
      this.$emit("add-data", {
        callback: () =>
          restAdapter
            .delete(
              `/api/inquiries/${this.inquiryId}/bom_file_delete/${this.unique_file_id}`
            )
            .then((response) => {
              this.resetForms();
              notification.success("File Deleted Successfully");
            }),
      });
    },
    openActions(id, event) {
      event.stopPropagation();
      if (this.activeActionsId === id) {
        this.activeActionsId = "";
      } else {
        this.activeActionsId = id;
      }
    },
    setMainImage(file, name) {
      this.resetActionContainers();
      restAdapter
        .post(`/api/inquiries/${this.inquiryId}/set_main_image/`, {
          main_image: file,
          name: name,
        })
        .then(() => {
          notification.success("Image set as main image successfully");
          this.refreshLog();
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.non-expanded-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #e0e0e0;
  cursor: pointer;
}
.expanded-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  margin-bottom: 1px;
  gap: 30px;
}
.bom-item-name {
  color: $primaryDark;
  font-weight: bold;
  font-size: 16px;
}
.bom-item-no-name {
  color: $primaryDark;
  font-weight: 100;
  font-size: 16px;
  opacity: 0.5;
}
.expand-icon {
  color: $primaryDark;
  font-size: 32px;
  cursor: pointer;
}
.shrink-icon {
  @extend .expand-icon;
  margin-bottom: 22px;
}
.delete-icon {
  color: #bfb7ca;
  font-size: 24px;
  cursor: pointer;
}
.new-file-icon {
  color: $primaryDark;
  font-size: 40px;
  cursor: pointer;
}
.new-composition-icon {
  color: $primaryDark;
  font-size: 24px;
  cursor: pointer;
  float: left;
}
.delete-composition-icon {
  color: #bfb7ca;
  font-size: 24px;
  cursor: pointer;
  margin-bottom: 22px;
}
.expanded-component {
  background-color: rgba($success, 0.17);
  padding: 10px;
}
.supplier-dropdown-container {
  width: calc(50% - 15px);
}
.textarea-container {
  margin-top: 20px;
}
.files-container-component {
  margin: 30px 0px;
  padding-bottom: 10px;
}
.files-container {
  position: relative;
  display: flex;
  align-items: center;
  padding: 10px;
  gap: 10px;
  border: 1px solid $primaryDarkest;
  border-radius: 5px;
}
.files-container-overflow {
  flex: 1;
  overflow-x: hidden;
  overflow-y: hidden;
}
.expanded-body {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  gap: 30px;
}
.expanded-body-item {
  width: 50%;
}
.expanded-footer {
  @extend .non-expanded-header;
  margin-top: -40px;
  justify-content: flex-end;
}
.measurement-table,
.table-header,
.table-data {
  border-spacing: 0px;
  border: 1px solid $primaryDarkest;
  border-collapse: collapse;
}
.measurement-table {
  width: 100%;
}
.supplier-table {
  @extend .measurement-table;
}
.table-header {
  padding: 2px;
  background-color: rgba($primaryDarkest, 0.15);
  font-weight: bold;
  font-size: 14px;
  color: $primaryDark;
}
.table-data {
  padding: 0px 5px;
  font-size: 14px;
  background-color: #ffffff;
}
.input-label {
  text-align: left;
  color: $primaryDarkest;
  font-weight: bold;
  width: 100%;
  margin: 5px 0px;
}
.supplier-name {
  color: $primaryDarkest;
}
.factory-card {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
}
.factory-name {
  @extend .supplier-name;
}
.new-file-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 118px;
  height: 118px;
  border: 1px dashed $primaryDark;
  border-radius: 4px;
  cursor: pointer;
}
.more-vert {
  background: $paginateText;
  width: 16px;
  height: 16px;
  float: right;
  border-radius: 50%;
  cursor: pointer;
}
.more-vert:hover {
  border: 0.5px solid $primaryDark;
  filter: drop-shadow(0px 4px 4px rgba(72, 49, 105, 0.15));
}
.more-vert-enhanced {
  position: absolute;
  right: 0;
  top: 0;
}
.more-vert-icon {
  font-size: 15px;
  vertical-align: unset;
}
.image-size {
  position: relative;
  margin-bottom: 2px;
  width: 100px;
  height: 100px;
  aspect-ratio: 1/1;
  background-size: cover;
  border-radius: 4px;
  filter: drop-shadow(0px 4px 4px rgba(72, 49, 105, 0.15));
}
.more-vert {
  position: absolute;
  top: 0;
  right: 0;
  background: $paginateText;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  display: flex;
  border: 0.5px solid #442d64;
}
.more-vert:hover {
  filter: drop-shadow(0px 4px 4px rgba(72, 49, 105, 0.15));
}
.more-vert-icon {
  font-size: 15px;
  vertical-align: unset;
}
.actions-container {
  display: none;
  height: auto;
  z-index: 8;
  position: absolute;
  background: rgba(255, 255, 255, 0.85);
  box-shadow: 2.96703px 13.8462px 11.8681px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  top: 32px;
}
.actions-list {
  overflow: visible;
  color: $infoMessage;
  cursor: pointer;
  padding: 6px 10px;
  z-index: 8;
}
.actions-list:hover {
  color: $tableBorder;
  background: rgba(255, 255, 255, 0.95);
}
.file-size {
  background-color: white;
  bottom: 0;
  position: absolute;
  right: 0;
  padding: 1px 5px;
  font-size: 12px;
}
.file-name {
  color: #442d65;
  font-size: 12px;
  word-break: break-all;
}
.file-date {
  color: #442d65;
  font-size: 13px;
  font-weight: 400;
  padding: 0px 15px;
  margin-bottom: 3px;
  word-break: break-all;
}
.dropdown-list::v-deep .v-text-field {
  transform: unset !important;
}
.dropdown-list::v-deep .v-label--active {
  transform: unset !important;
}
.item-row {
  display: flex;
  align-items: center;
  gap: 10px;
}
.item-select,
.item-input {
  flex: 1;
}
.error-message {
  text-align: right;
  width: 100%;
  color: $danger;
  margin-top: -25px;
  padding-right: 34px;
  margin-bottom: 15px;
}
.error-content {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.composition-text {
  text-align: left;
  flex: 1;
}

.percentage-text {
  text-align: right;
  flex: 1;
}
.files-slider {
  width: 100%;
}
.files-slider::v-deep {
  .v-slide-group__content {
    gap: 20px;
  }
  .v-slide-group__next--disabled button,
  .v-slide-group__prev--disabled button {
    color: #d3d3d3 !important;
    caret-color: #d3d3d3 !important;
  }
}
.rename-form {
  width: 70%;
  margin: auto;
}
.rename-title {
  text-align: left;
  color: $primaryDark;
  font-weight: 600;
  font-size: 16px;
  padding: 30px 0 15px 0;
}
.rename-input[type="text"],
select {
  height: 40px;
  width: 100%;
  border: none;
  border-bottom: 1.5px solid $primaryDark;
  color: $spinnerStroke;
}
input:focus {
  outline: none;
}
::placeholder {
  color: $primaryDark;
  font-weight: 400;
}
.rename-room-buttons {
  padding: 30px 0 15px 0;
}
.title-input::v-deep input {
  font-weight: bold;
  color: #473068;
}
.not-verified-text {
  color: #ff0000;
  text-align: left;
  margin-bottom: 10px;
}
.unable-to-find-factory:hover {
  text-decoration: underline;
  color: #c6a8ec;
}
.unable-to-find-factory {
  font-size: 14px;
  cursor: pointer;
  color: #442d65;
  position: sticky;
  top: 0px;
  padding: 8px 8px 0px 8px;
  margin: -8px 0px 8px 0px;
  background-color: inherit;
  z-index: 1;
}
.no-location-warning {
  font-size: 12px;
  color: $infoMessage;
}
</style>
