<template>
  <div>
    <div
      :id="category.title"
      class="sub-header"
      :style="`top: ${headerHeight}px;};`"
    >
      <div class="category-title">
        {{ category.title }}
      </div>
    </div>
    <div>
      <div v-for="(data, index) in bomData" :key="'name-' + index">
        <bom-item
          :isAuthority="isAuthority"
          :headerHeight="headerHeight"
          :inquiryId="inquiryId"
          :data="data"
          :category="category"
          :baseUrl="baseUrl"
          @refresh="refresh"
          :measurements="measurements"
          :suppliers="suppliers"
          :compositions="compositions"
          @refresh-log="refreshLog"
          @change-tab="changeTab"
          @add-data="addData"
          @remove-row="removeRow"
          @update-suppliers="updateSuppliers"
        />
      </div>
    </div>
    <div class="button-container">
      <v-tooltip v-if="!insertingItem" color="#473068" bottom>
        <template v-slot:activator="{ on }">
          <div v-on="on">
            <div class="add-icon" @click="addRow(category.name)">
              <v-icon style="font-size: 32px; color: #bfb7ca; margin-left: 5px"
                >mdi-plus-circle-outline
              </v-icon>
            </div>
          </div>
        </template>
        <div class="hover-container">
          <span>Add BOM Item</span>
        </div>
      </v-tooltip>
      <v-progress-circular
        v-else
        indeterminate
        :color="$parent.$el.style.color"
        size="20"
        width="1"
        style="margin-left: 10px; margin-top: 5px"
      ></v-progress-circular>
    </div>
  </div>
</template>

<script>
/* eslint-disable @typescript-eslint/camelcase */
import * as conf from "../../conf.yml";
import restAdapter from "../../restAdapter";
import BomItem from "./BomItem.vue";
import { bomDataBus } from "../../utils/bomDataBus";
import notification from "../../notification";

export default {
  components: { BomItem },
  name: "BomCategoryItem",
  props: {
    isAuthority: {
      type: Boolean,
      default: false,
    },
    headerHeight: {
      type: Number,
      suppliers: Array,
      factories: Array,
      default: 0,
    },
    inquiryId: {
      type: [String, Number],
      required: true,
    },
    bomData: {
      type: Array,
      default: () => [],
    },
    baseUrl: {
      type: String,
      default: conf.default.server.host,
    },
    category: {
      type: Object,
      required: true,
    },
    measurements: {
      type: Array,
      default: () => [],
    },
    suppliers: {
      type: [Array, Object],
      default: () => [],
    },
    compositions: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    insertingItem: false,
    headerWidth: 0,
    scrollY: 0,
  }),
  watch: {
    suppliers: {
      handler() {
        this.bomData.forEach((item) => {
          item.supplier_ids = item.supplier_ids.filter((supplierId) =>
            this.suppliers.find((supplier) => supplierId == supplier.id)
          );
        });
      },
      deep: true,
    },
  },
  created() {
    bomDataBus.$on("force-update", this.addData);
    window.addEventListener("resize", this.screenSizeHandler);
    window.addEventListener("scroll", this.screenScrollHandler);
  },
  destroyed() {
    window.removeEventListener("scroll", this.screenScrollHandler);
    window.removeEventListener("resize", this.screenSizeHandler);
  },
  methods: {
    async addData(addDataOptions = {}) {
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      const callback = addDataOptions.callback || (() => {});
      const toBeSubmitted = this.bomData.map((item) => {
        const updatedData = addDataOptions.data || item;
        return {
          ...updatedData,
          suppliers: updatedData.supplier_ids.map((supplierId) => {
            const usedSupplierAsFactory = updatedData.factories[supplierId]
              ?.length
              ? updatedData.factories[supplierId][
                  updatedData.factories[supplierId].length - 1
                ] == "useCompanyAsFactory"
              : false;

            return {
              id: supplierId,
              used_supplier_as_factory: usedSupplierAsFactory,
              factories: usedSupplierAsFactory
                ? []
                : updatedData.factories[supplierId]?.filter(
                    (factoryId) =>
                      factoryId && factoryId !== "useCompanyAsFactory"
                  ) || [],
            };
          }),
        };
      });
      const response = await restAdapter.put(
        `/api/inquiries/${this.inquiryId}/bom`,
        {
          bom_data: {
            [this.category.name]: toBeSubmitted,
          },
        }
      );
      this.refreshLog();
      if (addDataOptions.refresh) bomDataBus.$emit("force-refresh");
      callback();
      return response;
    },
    async addRow(name) {
      this.insertingItem = true;
      await this.addData();
      restAdapter
        .post(`/api/inquiries/${this.inquiryId}/bom`, { category: name })
        .then((response) => {
          this.insertingItem = false;
          this.refresh();
          notification.success(
            "Bill of Materials (BOM) item added successfully"
          );
        });
    },
    async removeRow(id) {
      if (confirm("Remove this row?")) {
        await this.addData();
        restAdapter
          .delete(`/api/inquiries/${this.inquiryId}/delete_bom_item/${id}`)
          .then((response) => {
            this.$emit("refresh");
            notification.success(
              "Bill of Materials (BOM) item deleted successfully"
            );
          });
      }
    },
    refresh() {
      this.$emit("refresh");
    },
    refreshLog() {
      this.$emit("refresh-log");
    },
    changeTab() {
      this.$emit("change-tab");
    },
    updateSuppliers(suppliers) {
      this.$emit("update-suppliers", suppliers);
    },
    screenSizeHandler(e) {
      this.windowSize = window.innerWidth;
      this.screenScrollHandler(e);
      return this.windowSize;
    },
    screenScrollHandler(e) {
      const categoryHeader = document.getElementById(this.category.title);
      const isPositionFixed = categoryHeader.style.position == "fixed";

      const categoryHeaderBounding = categoryHeader.getBoundingClientRect();
      this.headerWidth = categoryHeaderBounding.width;

      if (categoryHeaderBounding.top < 0 && !isPositionFixed) {
        this.scrollY = window.scrollY;
        categoryHeader.style.position = "fixed";
        categoryHeader.style.width = `${this.headerWidth}px`;
      } else if (window.scrollY < this.scrollY && isPositionFixed) {
        categoryHeader.style.position = "relative";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.sub-header {
  z-index: 5;
}

.category-title {
  padding: 4px 10px;
  text-align: left;
  font-size: 17px;
  font-weight: bold;
  color: #473068;
  background: #eef1fa;
}

.button-container {
  display: flex;
  justify-content: flex-start;
  margin: 5px 0 15px 0;
}

.add-icon {
  cursor: pointer;
}
</style>
